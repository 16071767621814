import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-search-for-linking',
  templateUrl: './vendor-search-for-linking.component.html',
  styleUrls: ['./vendor-search-for-linking.component.scss']
})
export class VendorSearchForLinkingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
