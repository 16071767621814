import { ElementRef, Output , EventEmitter} from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'src/app/layout/client/services/client-service.service';
import { AppApiConfig } from 'src/app/shared/constants/app-api.config';
import { ExcelService } from '../../services/excel.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-post-auction-compare',
  templateUrl: './post-auction-compare.component.html',
  styleUrls: ['./post-auction-compare.component.scss']
})
export class PostAuctionCompareComponent implements OnInit {
  @Input('prId') prId ;
  @Input ('ppoData') ppoData ;
  selectedData: any = [];
  @ViewChild('exportTable') exportTable: ElementRef;
  pageRecordSize: any;
  pageOptions: any;
  auctionCategoryList: any;
  selectedAucCategoryId: any;
  selectedAuc: any;
  rfqWiseList: any[] = [];
  itemWiseList: any[] = [];
  auctionWiseList: any[] = [];
  @Output() public auctionDetails =   new EventEmitter<any>();
  itemData: any;
  auctionItemsHeaders = [
    { field: 'desc', header: 'ItemName', width: '145px' },
    { field: 'venodorName', header: 'Vendor Name', width: '145px' },
    { field: 'quotePrice', header: 'Pre Auction', width: '145px' },
    { field: 'auctionPrice', header: 'Post Auction', width: '145px' },
  ];
  filteredAuctionList: any[] = [];
  selectedAucType: any;
  constructor(private clientService: ClientService,
    private excelService: ExcelService) { }

  ngOnInit() {
    this.pageRecordSize = AppApiConfig.GRID_PAGE_INFO.initpageSize;
    this.pageOptions = AppApiConfig.GRID_PAGE_INFO.pageOptions;
    console.log(this.ppoData);
    const reqObj = {
      'id': this.prId
    };
    // this.itemData={
    //   "headers": [
    //     {
    //       "vname": "gobiboo",
    //       "vid": "GOB200907144350-quote",
    //       "qid": "QuotePrice"
    //     },
    //     {
    //       "vname": "gobiboo",
    //       "vid": "GOB200907144350-auc",
    //       "qid": "AuctionPrice"
    //     },
    //     {
    //       "vname": "soft world",
    //       "vid": "SOF20201212-quote",
    //       "qid": "QuotePrice"
    //     },
    //     {
    //       "vname": "soft world",
    //       "vid": "SOF20201212-auc",
    //       "qid": "AuctionPrice"

    //     }
    //   ],
    //   "itemsHeaders": null,
    //   "items": [
    //     {
    //       "description": "Animal books",
    //       "data": [
    //         {
    //           "vendorid": "SOF20201212-quote",
    //           "vendorname": "soft world",
    //           "totalamount": "450",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },
    //         {
    //           "vendorid": "SOF20201212-auc",
    //           "vendorname": "soft world",
    //           "totalamount": "30",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },
    //         {
    //           "vendorid": "GOB200907144350",
    //           "vendorname": "gobiboo",
    //           "totalamount": "600",
    //           "auctionAmont": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },
    //         {
    //           "vendorid": "GOB200907144350-auc",
    //           "vendorname": "gobiboo",
    //           "totalamount": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }
    //       ]
    //     },
    //     {
    //       "description": "Vehicles books",
    //       "data": [
    //         {
    //           "vendorid": "SOF20201212-quote",
    //           "vendorname": "soft world",
    //           "totalamount": "450",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },  {
    //           "vendorid": "SOF20201212-auc",
    //           "vendorname": "soft world",
    //           "totalamount": "30",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },
    //         {
    //           "vendorid": "GOB200907144350-quote",
    //           "vendorname": "gobiboo",
    //           "totalamount": "600",
    //           "auctionAmont": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }, {
    //           "vendorid": "GOB200907144350-auc",
    //           "vendorname": "gobiboo",
    //           "totalamount": "420",
    //           "auctionAmont": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }
    //       ]
    //     },
    //     {
    //       "description": "computer book",
    //       "data": [
    //         {
    //           "vendorid": "SOF20201212-quote",
    //           "vendorname": "soft world",
    //           "totalamount": "450",
    //           "auctionAmont": "30",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }, {
    //           "vendorid": "SOF20201212-auc",
    //           "vendorname": "soft world",
    //           "totalamount": "10",
    //           "auctionAmont": "30",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         },
    //         {
    //           "vendorid": "GOB200907144350-quote",
    //           "vendorname": "gobiboo",
    //           "totalamount": "600",
    //           "auctionAmont": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }, {
    //           "vendorid": "GOB200907144350-auc",
    //           "vendorname": "gobiboo",
    //           "totalamount": "40",
    //           "auctionAmont": "420",
    //           "qid": "0402dd55-b757-4ac1-9d32-4b68c9831ede"
    //         }
    //       ]
    //     }
    //   ]
    // }
    this.clientService.getRfqwiseAuctionIdsByPR(reqObj).subscribe((data) => this.successCallBack(data));
  }

  successCallBack(data) {
    console.log(data);
    this.auctionCategoryList = data;
  }
  auctionIdChange(data) {
    this.selectedAuc = [];
    this.auctionWiseList = [];
    this.filteredAuctionList = [];
    console.log(data);
    console.log(this.selectedAucCategoryId);
    this.auctionCategoryList.forEach(element => {
      if (element.id === this.selectedAucCategoryId) {
        this.selectedAuc = element;
        if (element.auctionCategory === 'rfq total wise') {
          this.selectedAucType = element.auctionCategory;
        this.clientService.getRFQWiseSummary(element).subscribe((data: any) => {
          this.itemData = data || {};
          this.auctionDetails.emit(this.itemData);

        });
        } else {
          this.selectedAucType = element.auctionCategory;
          this.clientService.getItemWiseSummary(element).subscribe((data: any) => {
          this.itemData = data || {};
          this.auctionDetails.emit(this.itemData);

        });
      }
    }
  });
  }

  exportAsXLSX(): void {
    const ws: xlsx.WorkSheet =
    xlsx.utils.table_to_sheet(this.exportTable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = 'sample_' + new Date().getTime() + '.xlsx';
    xlsx.writeFile(wb, filename);
}

}
