 export class PO {
    poValue: number;
    desc: string;
    advanceAmount: number;
    clientPONum: string;
    podesc: string;
    otherTerms: string;
    paymentTerms: string;
    deliveryTerms: string;
    vendorAddress: any;
    note: string;
    frieghtTerms: string;
    warranty: string;
}
