import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CommonShareRoutingModule } from './common-share-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatExpansionModule,
    MAT_DIALOG_DATA, MAT_DATE_LOCALE
} from '@angular/material';
import { PageInfoComponent } from './components/page-info/page-info.component';
import {TooltipModule} from 'primeng/tooltip';
import { NgxDraggableDomModule } from 'ngx-draggable-dom';
import { DragDirective } from './directives/dragAndDropDirective';
import { CorrespondenceComponent } from './components/correspondence/correspondence.component';
import { VendorSearchComponent } from './components/vendor-search/vendor-search.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { CommentAlertBellBlinkComponent } from './components/comment-alert-bell-blink/comment-alert-bell-blink.component';
import { QuotCompareComponent } from './components/quot-compare/quot-compare.component';
import { FilePipe } from './services/file.pipe';
import { QuotViewDetailsComponent } from './components/quot-view-details/quot-view-details.component';
import { DocGridComponent } from './components/doc-grid/doc-grid.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PpoCreateComponent } from './components/ppo-create/ppo-create.component';
import { PpoViewModalComponent } from './components/ppo-view-modal/ppo-view-modal.component';
import { PpoItemsComponent } from './components/ppos/ppo-items/ppo-items.component';
import { PrDetailsViewComponent } from './components/pr-details-view/pr-details-view.component';
import { PpoQuotCompareViewComponent } from './components/ppo-quot-compare-view/ppo-quot-compare-view.component';
import { CreateAuctionModalComponent } from './components/create-auction-modal/create-auction-modal.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { CreateRarAuctionComponent } from './components/create-auction-modal/create-rar-auction/create-rar-auction.component';
import { CreateRaiAuctionComponent } from './components/create-auction-modal/create-rai-auction/create-rai-auction.component';
import { CreateSealedBidAuctionComponent } from './components/create-auction-modal/create-sealed-bid-auction/create-sealed-bid-auction.component';
import { NgMarqueeModule } from 'ng-marquee';
import { PostAuctionCompareComponent } from './components/post-auction-compare/post-auction-compare.component';
import { PpoTermsConditionsComponent } from './components/ppo-terms-conditions/ppo-terms-conditions.component';
import { PpoDocumentsComponent } from './components/ppo-documents/ppo-documents.component';
import { PoCreateComponent } from './components/po/po-create/po-create.component';
import { CommonGridComponent } from './components/common-grid/common-grid.component';
import { AccordionModule, CalendarModule, ChartModule, DialogModule, MultiSelectModule, SidebarModule, StepsModule } from 'primeng/primeng';
import { CommonAttachmentsComponent } from './components/common-attachments/common-attachments.component';
import { FormControlErrorsMessagesDisplayComponent } from './components/form-control-errors-messages-display/form-control-errors-messages-display.component';
import { LinkedPrPpoCreateComponent } from './components/linked-pr-ppo-create/linked-pr-ppo-create.component';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { VendorSearchForLinkingComponent } from './components/vendor-search-for-linking/vendor-search-for-linking.component';
import { ClientSearchForLinkingComponent } from './components/client-search-for-linking/client-search-for-linking.component';
import {VendorViewModelComponent} from './components/vendor-view-model/vendor-view-model.component';
import {AddOrEditVendorModalComponent } from './components/add-or-edit-vendor-modal/add-or-edit-vendor-modal.component';
import { PpoReportsComponent } from './components/ppo-reports/ppo-reports.component';
import { VendorInfoIconDialogComponent } from './components/vendor-info-icon-dialog/vendor-info-icon-dialog.component';
import { TooltipModule as ngBToolTipModule  } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PpoRfqVendorsComponent } from './components/ppo-rfq-vendors/ppo-rfq-vendors.component';
import { SubCategoryDropdownComponent } from './components/sub-category-dropdown/sub-category-dropdown.component';
import { PriceAnalyticsGraphModalComponent } from './price-analytics-graph-modal/price-analytics-graph-modal.component';
import { PriceTrendChartComponent } from 'src/app/layout/client/price-trend-chart/price-trend-chart.component';
import { AuthenticateLoggedUserComponent } from './components/authenticate-logged-user/authenticate-logged-user.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';
import { GmtVendorInfoModalComponent } from './components/gmt-vendor-info-modal/gmt-vendor-info-modal.component';
import { DownloadAttachmentsComponent } from './components/download-attachments/download-attachments.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        CommonShareRoutingModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        MatCardModule,
        MatDividerModule,
        MatGridListModule,
        MatTabsModule,
        MatExpansionModule,
        AgGridModule,
        TableModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDraggableDomModule,
        SelectDropDownModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatRadioModule,
        MatSlideToggleModule,
        NgMarqueeModule,
        TooltipModule,
        MultiSelectModule,
        DropdownModule,
        AutoCompleteModule,
        OverlayPanelModule,
        ngBToolTipModule,
        AccordionModule,
        NgbModule.forRoot(),
        SidebarModule,
        CalendarModule,
        DialogModule,
        ChartModule,
        StepsModule,
        ToggleButtonModule,
        MatButtonToggleModule
    ],

    declarations: [PageInfoComponent, DragDirective, CorrespondenceComponent, VendorSearchComponent, CommentAlertBellBlinkComponent, QuotCompareComponent, FilePipe, QuotViewDetailsComponent, DocGridComponent, PpoCreateComponent, PpoViewModalComponent, PpoItemsComponent, PrDetailsViewComponent, PpoQuotCompareViewComponent, CreateAuctionModalComponent, NumbersOnlyDirective, CreateRarAuctionComponent, CreateRaiAuctionComponent, CreateSealedBidAuctionComponent, PostAuctionCompareComponent, PpoTermsConditionsComponent, PpoDocumentsComponent, PoCreateComponent, CommonGridComponent, CommonAttachmentsComponent, FormControlErrorsMessagesDisplayComponent, VendorSearchForLinkingComponent, ClientSearchForLinkingComponent, LinkedPrPpoCreateComponent, VendorViewModelComponent, AddOrEditVendorModalComponent, PpoReportsComponent,  VendorInfoIconDialogComponent, PpoRfqVendorsComponent, SubCategoryDropdownComponent, PriceAnalyticsGraphModalComponent, PriceTrendChartComponent, AuthenticateLoggedUserComponent, BlockCopyPasteDirective,
        GmtVendorInfoModalComponent,
        DownloadAttachmentsComponent],
    exports: [
        DialogModule,
        AgGridModule,
        CommonModule,
        CommonShareRoutingModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        MatCardModule,
        MatDividerModule,
        MatGridListModule,
        MatTabsModule,
        MatExpansionModule,
        TableModule,
        FormsModule,
        ReactiveFormsModule,
        PageInfoComponent,
        NgxDraggableDomModule,
        DragDirective,
        SelectDropDownModule,
        CommentAlertBellBlinkComponent,
        QuotCompareComponent,
        PpoViewModalComponent,
        FilePipe,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PrDetailsViewComponent,
        MatRadioModule,
        MatSliderModule,
        NgMarqueeModule,
        CommonGridComponent,
        MultiSelectModule,
        CommonAttachmentsComponent,
        FormControlErrorsMessagesDisplayComponent,
        DropdownModule,
        AutoCompleteModule,
        VendorViewModelComponent,
        AddOrEditVendorModalComponent,
        VendorInfoIconDialogComponent,
        OverlayPanelModule,
        ngBToolTipModule,
        NgbModule,
        AccordionModule ,
        SubCategoryDropdownComponent,
        SidebarModule,
        CalendarModule,
        ChartModule,
        PriceAnalyticsGraphModalComponent,
        PriceTrendChartComponent,
        StepsModule,
        AuthenticateLoggedUserComponent,
        ToggleButtonModule,
        MatButtonToggleModule,
        BlockCopyPasteDirective,
        GmtVendorInfoModalComponent,
        DownloadAttachmentsComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        DatePipe
    ],
    entryComponents: [CorrespondenceComponent,
        VendorSearchComponent,
        CommentAlertBellBlinkComponent,
        QuotViewDetailsComponent,
        PpoCreateComponent,
        PpoViewModalComponent,
        CreateAuctionModalComponent,
        PoCreateComponent,
        LinkedPrPpoCreateComponent,
        VendorViewModelComponent,
        AddOrEditVendorModalComponent,
        PriceAnalyticsGraphModalComponent,
        AuthenticateLoggedUserComponent
    ]
})
export class CommonShareModule { }
